/* eslint-disable react/react-in-jsx-scope */
import { Routes, Route, useNavigate } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Table from '../UI/Table';
import List from '../UI/List';
import Customer from '../pages/Customer';
import CustomerProfile from '../pages/CustomerProfile';
import Bill from '../pages/Bill';
import Payment from '../pages/Payment';
import AddCustomer from '../components/AddCustomer';
import Login from '../components/auth/Login';
import { useEffect } from 'react';
import Report from './Report';
import Activity from './Activity';
import ResetPassword from '../components/auth/ResetPassword';
import Product from './Product';
import Invoice from './Invoice';
import Company from './Company';
import AddCompany from '../components/addCompany';
import CompanyProfile from './CompanyProfile';
import AddCompanyBill from '../components/AddCompanyBill';
import AddCompanyPayment from '../components/AddCompanyPayment';
import Expense from './Expense';
import Policy from './Policy';
import InvoiceList from '../components/Invoice/InvoiceList';
export default function RoutePage() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, []);
  return (
    <Routes>
      <Route exact path="/" element={<Dashboard />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/reset" element={<ResetPassword />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/table" element={<Table />} />
      <Route path="/list" element={<List />} />
      <Route path="/customer" element={<Customer />} />
      <Route exact path="/customer/:id" element={<CustomerProfile />} />
      <Route exact path="/addCustomer" element={<AddCustomer />} />
      <Route path="/company" element={<Company />} />
      <Route exact path="/company/:id" element={<CompanyProfile />} />
      <Route exact path="/addCompany" element={<AddCompany />} />
      <Route exact path="/company/:id/addPayment" element={<AddCompanyPayment />} />
      <Route exact path="/company/:id/addBill" element={<AddCompanyBill />} />
      <Route path="/bill" element={<Bill />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/product" element={<Product />} />
      <Route path="/expense" element={<Expense />} />
      <Route path="/report" element={<Report />} />
      <Route path="/activity" element={<Activity />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/invoice" element={<InvoiceList />} />
      <Route path="/addInvoice" element={<Invoice />} />
    </Routes>
  );
}
