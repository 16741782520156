/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { httpCall, HTTP_METHODS } from '../services/httpService';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import avatar from '../images/avatars/2s.png';
import { formatTime, getBillAmount, getPaymentAmount, numberFormat } from '../share/Utils';
import useText from '../share/hooks/useText';
import moment from 'moment';

function Customer() {
  useText('Customers', 'customer'); // update context for title and page

  const { data: customerData, isLoading, error, refetch } = useQuery('customer', fetchData);
  const placeData = JSON.parse(localStorage.getItem('places'));
  const [totalAmount, setTotalAmount] = useState(0);
  const [placeId, setPlaceId] = useState('all');
  const [customerName, setCustomerName] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = user?.roleName === 'Admin';
  const [filteredDataValue, setFilteredDataValue] = useState([]);
  const SESSION = localStorage.getItem('SESSION');

  useEffect(() => {
    filterData();
  }, [customerData, placeId, customerName]);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="spinner-border color-highlight" role="status"></div>
      </div>
    );
  }

  if (error) return 'An error has occurred: ' + error.message;

  async function fetchData() {
    const response = await httpCall(HTTP_METHODS.GET, 'customer');
    setTotalAmount(response.totalAmount);
    return response.data;
  }

  function filterData() {
    if (customerData) {
      const filteredData = customerData.filter((customer) => {
        const isPlaceIdMatch = placeId === 'all' || customer?.place?.uuid === placeId;
        const isCustomerNameMatch = customer.name
          ?.toLowerCase()
          .includes(customerName.toLowerCase());
        return isPlaceIdMatch && isCustomerNameMatch;
      });
      let totalSale = 0;
      let totalPaymentReceived = 0;
      const customerDataValue = placeId === 'all' ? customerData : filteredData;
      customerDataValue.map((data) => {
        totalSale += getBillAmount(data);
        totalPaymentReceived += getPaymentAmount(data);
      });
      setFilteredDataValue(filteredData);
      setTotalAmount(totalSale - totalPaymentReceived);
      setFilteredDataValue(filteredData);
    }
  }

  return (
    <div id="page">
      <div className="page-content header-clear-medium">
        <div className="card card-style">
          <div className="content">
            <div className="row">
              <h4 className={`${isAdmin ? 'col-5 mb-3 ml-1 font-18' : 'col-12 mb-3 ml-1 font-18'}`}>
                You will get :
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  {' '}
                  {numberFormat(totalAmount || 0)}
                </span>{' '}
              </h4>
              {totalAmount && isAdmin && (
                <div className="form-custom col-7 form-label form-icon mb-3">
                  <i className="bi bi-check-circle font-13"></i>
                  <select
                    onChange={(e) => {
                      setPlaceId(e.target.value);
                    }}
                    className="form-select rounded-xs"
                    id="c6"
                    aria-label="Floating label select example">
                    <option value="all">All</option>
                    {placeData?.length &&
                      placeData.map((place) => (
                        <option key={place.uuid} value={place.uuid}>
                          {place.name}
                        </option>
                      ))}
                  </select>
                  <label htmlFor="c1" className="color-theme form-label-active">
                    Select Place
                  </label>
                </div>
              )}
            </div>

            <div className="form-custom form-label form-icon mb-3">
              <i className="color-blue-dark bi bi-search"></i>
              <input
                type="text"
                onChange={(e) => {
                  setCustomerName(e.target.value);
                }}
                className="form-control rounded-xs"
                id="c1"
                placeholder={`${filteredDataValue?.length || customerData?.length} customers`}
              />
            </div>
            <div className="list-group list-custom list-group-m rounded-m">
              {filteredDataValue.length > 0
                ? filteredDataValue.map((customer) => (
                    <Link
                      key={customer.uuid}
                      to={`/customer/${customer.uuid}`}
                      state={{ customer }}
                      className="list-group-item d-flex">
                      <div className="pe-3 me-auto d-flex">
                        <img src={customer.image || avatar} width="40" className="me-2 rounded-m" />
                        <div className="ps-1">
                          <strong className="font-600">{customer?.name?.toUpperCase()}</strong>
                          <span className="font-600">{`${customer.area} ${customer?.place?.name}`}</span>
                        </div>
                      </div>
                      <div className="mt-n1 pb-1">
                        <div className="mx-auto" style={{ color: 'green' }}>
                          {numberFormat(getBillAmount(customer))}
                        </div>
                        <div className="mx-auto" style={{ color: 'red' }}>
                          {numberFormat(getBillAmount(customer) - getPaymentAmount(customer))}
                        </div>
                        {/* <span className="p-1">{formatTime(customer.updatedAt)}</span> */}
                      </div>
                    </Link>
                  ))
                : customerData &&
                  customerData.map((customer) => (
                    <Link
                      key={customer.uuid}
                      to={`/customer/${customer.uuid}`}
                      state={{ customer }}
                      className="list-group-item d-flex">
                      <div className="pe-3 me-auto d-flex">
                        <img src={customer.image || avatar} width="40" className="me-2 rounded-m" />
                        <div className="ps-1">
                          <strong className="font-600">{customer?.name?.toUpperCase()}</strong>
                          <span className="font-800">{`${customer.area}(${customer.place?.name})`}</span>
                        </div>
                      </div>
                      <div className="mb-n2">
                        <div className="mx-auto" style={{ color: 'red' }}>
                          {numberFormat(getBillAmount(customer) - getPaymentAmount(customer))}
                        </div>
                        <span className="p-1 font-900">
                          {moment(customer.updatedAt).format('MMM Do')}
                        </span>
                      </div>
                    </Link>
                  ))}
            </div>
          </div>
          {customerData?.length === 0 && (
            <div className="p-4">
              <h1>Add customer from below add icon...</h1>
            </div>
          )}
          {isAdmin && (
            <div className="float" data-toast="toast-top-3">
              <Link to="/addCustomer" className="btn bg-blue-light border-blue-dark">
                <i className="bi bi-person-plus-fill font-22"></i>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Customer;
