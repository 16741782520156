/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import CommonContext from '../commonContext';
import imgLogo from '../images/pictures/7s.jpg';
function Menu(props) {
  const { setContextData } = useContext(CommonContext);

  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));
  const SESSION = localStorage.getItem('SESSION');

  function logout() {
    const resp = confirm('Are you sure?');
    if (resp) {
      localStorage.clear();
      props.setShow(false);
      setContextData({
        title: 'pocketKhata',
        page: 'pocketKhata',
        toast: {
          type: '',
          show: false,
          message: ''
        },
        token: '',
        user: ''
      });
    }
  }

  return (
    <div className="">
      <div className="bg-theme mx-3 rounded-m shadow-m mt-3 mb-3">
        <div className="d-flex px-2 pb-2 pt-2">
          <div>
            <a>
              <img src={user?.image || imgLogo} width="45" className="rounded-s" alt="img" />
            </a>
          </div>
          <div className="ps-2 align-self-center">
            <h5 className="ps-1 mb-0 line-height-xs pt-1">{user?.name}</h5>
            <h6 className="ps-1 mb-0 font-400 opacity-40">{user?.roleName}</h6>
          </div>
          <div className="ms-auto" onClick={() => props.setShow(false)}>
            <a href="#" data-bs-toggle="dropdown" className="icon icon-m ps-3">
              <i className="bi bi-caret-left-fill"></i>
              {/* <i className="bi bi-three-dots-vertical font-18 color-theme"></i> */}
            </a>
            <div className="dropdown-menu  bg-transparent border-0 mt-n1 ms-3">
              <div className="card card-style rounded-m shadow-xl mt-1 me-1">
                <div className="list-group list-custom list-group-s list-group-flush rounded-xs px-3 py-1">
                  <a
                    href="page-profile-admin.html"
                    className="color-theme opacity-70 list-group-item py-1">
                    <strong className="font-500 font-12">Your Profile</strong>
                    <i className="bi bi-chevron-right"></i>
                  </a>
                  <a
                    href="page-activity.html"
                    className="color-theme opacity-70 list-group-item py-1">
                    <strong className="font-500 font-12">Notifications</strong>
                    <i className="bi bi-chevron-right"></i>
                  </a>
                  <a
                    href="page-login-2.html"
                    className="color-theme opacity-70 list-group-item py-1">
                    <strong className="font-500 font-12">Log Out</strong>
                    <i className="bi bi-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span className="menu-divider">NAVIGATION</span>
      <div className="menu-list">
        <div className="card card-style rounded-m p-3 py-2 mb-0">
          {user?.roleName === 'Admin' && (
            <Link to="/company" onClick={() => props.setShow(false)} id="nav-homes">
              <i className="gradient-blue shadow-bg shadow-bg-xs bi bi-house-fill"></i>
              <span>Company</span>
              <i className="bi bi-chevron-right"></i>
            </Link>
          )}
          <Link to="/customer" onClick={() => props.setShow(false)} id="nav-comps">
            <i className="gradient-red shadow-bg shadow-bg-xs bi bi-gear-fill"></i>
            <span>Customer</span>
            <i className="bi bi-chevron-right"></i>
          </Link>
          {/* <Link to="/report" onClick={() => props.setShow(false)} id="nav-pages">
            <i className="gradient-green shadow-bg shadow-bg-xs bi bi-heart-fill"></i>
            <span>Report</span>
            <i className="bi bi-chevron-right"></i>
          </Link> */}
          {/* <Link to="/invoice" onClick={() => props.setShow(false)} id="nav-pages">
            <i className="gradient-green shadow-bg shadow-bg-xs bi bi-heart-fill"></i>
            <span>Invoice</span>
            <i className="bi bi-chevron-right"></i>
          </Link> */}
          {/* <Link to="/payment" onClick={() => props.setShow(false)} id="nav-media">
            <i className="gradient-yellow shadow-bg shadow-bg-xs bi bi-image-fill"></i>
            <span>Payment</span>
            <i className="bi bi-chevron-right"></i>
          </Link> */}
          {user?.roleName === 'Admin' && (
            <Link to="/expense" onClick={() => props.setShow(false)} id="nav-media">
              <i className="gradient-yellow shadow-bg shadow-bg-xs bi bi-image-fill"></i>
              <span>Expense</span>
              <i className="bi bi-chevron-right"></i>
            </Link>
          )}
          {/* <Link to="/signup" onClick={() => props.setShow(false)} id="nav-mails">
            <i className="gradient-magenta shadow-bg shadow-bg-xs bi bi-envelope-fill"></i>
            <span>Signup</span>
            <i className="bi bi-chevron-right"></i>
          </Link> */}
        </div>
      </div>

      <span className="menu-divider mt-4">SESSION</span>
      <div className="menu-list">
        <div className="card card-style rounded-m p-3 py-2 mb-0">
          {user?.roleName === 'Admin' && (
            <Link
              className={SESSION === 'SESSION_2023_2024' ? 'bg-blue-light' : ''}
              to="/customer"
              onClick={() => {
                props.setShow(false);
                localStorage.setItem('SESSION', 'SESSION_2023_2024');
              }}
              id="nav-homes">
              <i className="gradient-blue shadow-bg shadow-bg-xs bi bi-calendar-range"></i>
              <span>2023-2024</span>
              <i className="bi bi-chevron-right"></i>
            </Link>
          )}
          {user?.roleName === 'Admin' && (
            <Link
              className={SESSION === 'SESSION_2024_2025' ? 'bg-blue-light' : ''}
              to="/customer"
              onClick={() => {
                props.setShow(false);
                localStorage.setItem('SESSION', 'SESSION_2024_2025');
              }}
              id="nav-homes">
              <i className="gradient-blue shadow-bg shadow-bg-xs bi bi-calendar-range"></i>
              <span>2024-2025</span>
              <i className="bi bi-chevron-right"></i>
            </Link>
          )}
        </div>
      </div>

      <span className="menu-divider mt-4">PRICE LIST</span>
      <div className="menu-list">
        <div className="card card-style rounded-m p-3 py-2 mb-0">
          <a
            href="https://www.awzinghealthcare.com/product"
            target="_blank"
            onClick={() => props.setShow(false)}
            id="nav-comps"
            rel="noreferrer">
            <i className="gradient-red shadow-bg shadow-bg-xs bi bi-building"></i>
            <span>AWZING</span>
            <i className="bi bi-chevron-right"></i>
          </a>
          {/* <Link
            to={{ pathname: 'https://awzing.in/' }}
            target="_blank"
            onClick={() => props.setShow(false)}
            id="nav-comps">
            <i className="gradient-red shadow-bg shadow-bg-xs bi bi-building"></i>
            <span>AWZING</span>
            <i className="bi bi-chevron-right"></i>
          </Link> */}
        </div>
      </div>

      {/* <span className="menu-divider mt-4">MANAGE</span>
      <div className="menu-list">
        <div className="card card-style rounded-m p-3 py-2 mb-0">
          <Link to="/login">
            <i className="gradient-highlight shadow-bg shadow-bg-xs bi bi-palette-fill"></i>
            <span>Expense Category</span>
            <i className="bi bi-chevron-right"></i>
          </Link>
          <Link to="/login">
            <i className="gradient-highlight shadow-bg shadow-bg-xs bi bi-palette-fill"></i>
            <span>Place</span>
            <i className="bi bi-chevron-right"></i>
          </Link>
          <Link to="/login">
            <i className="gradient-highlight shadow-bg shadow-bg-xs bi bi-palette-fill"></i>
            <span>User</span>
            <i className="bi bi-chevron-right"></i>
          </Link>
        </div>
      </div> */}

      <span className="menu-divider mt-4">SETTINGS</span>
      <div className="menu-list">
        <div className="card card-style rounded-m p-3 py-2 mb-0">
          {!token && (
            <Link to="/login" onClick={() => props.setShow(false)}>
              <i className="gradient-highlight shadow-bg shadow-bg-xs bi bi-palette-fill"></i>
              <span>Login</span>
              <i className="bi bi-chevron-right"></i>
            </Link>
          )}
          {token && (
            <Link to="/login" onClick={logout}>
              <i className="gradient-highlight shadow-bg shadow-bg-xs bi bi-palette-fill"></i>
              <span>Logout</span>
              <i className="bi bi-chevron-right"></i>
            </Link>
          )}
          <a href="#" data-toggle-theme data-trigger-switch="switch-1">
            <i className="gradient-dark shadow-bg shadow-bg-xs bi bi-moon-fill font-13"></i>
            <span>Dark Mode</span>
            <div className="form-switch ios-switch switch-green switch-s me-2">
              <input type="checkbox" data-toggle-theme className="ios-input" id="switch-1" />
              <label className="custom-control-label" htmlFor="switch-1"></label>
            </div>
          </a>
        </div>
      </div>

      <p className="text-center mb-0 mt-n3 pb-3 font-9 text-uppercase font-600 color-theme">
        Made with <i className=" font-9 px-1 bi bi-heart-fill color-red-dark"></i> by Enabled in{' '}
        <span className="copyright-year"></span>.
      </p>
    </div>
  );
}

export default Menu;
