/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom';
import useText from '../share/hooks/useText';
import { formatTime, numberFormat } from '../share/Utils';
import { useEffect, useRef, useState } from 'react';
import { HTTP_METHODS, httpCall } from '../services/httpService';

/* eslint-disable react/react-in-jsx-scope */
export default function CombinedData(props) {
  const componentRef = useRef();
  const [customerData, setCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="spinner-border color-highlight" role="status"></div>
      </div>
    );
  }

  // if (error) return 'An error has occurred: ' + error.message;

  async function fetchData() {
    let url = `customer/ledger`;
    // if (dateRange.length) {
    //   url = `customer/ledger&start_date=${dateRange[0]}&end_date=${dateRange[1]}`;
    // }
    setIsLoading(true);
    const response = await httpCall(HTTP_METHODS.GET, url);
    setCustomerData(response.data);
    setIsLoading(false);
  }
  // const { data, customerData, netBalance, setActionSheetShow } = props;

  const user = JSON.parse(localStorage.getItem('user'));
  // const isAdmin = user?.roleName === 'Admin';
  // useText(`${customerData?.name?.slice(0, 11)}(${customerData?.place.name})`, 'profile');

  function editBillForm(event) {
    props.setBillFormValue(event);
    props.setBillModalShow(true);
  }

  function editPaymentForm(event) {
    props.setPaymentFormValue(event);
    props.setPaymentModalShow(true);
  }

  return (
    <div id="page">
      <div className="header-clear-medium"></div>
      <div className="card card-style">
        <div className="content">
          <div className="form-custom form-label form-icon mb-3">
            <i className="color-blue-dark bi bi-search"></i>
            <input
              type="text"
              onChange={(e) => {
                // setCustomerName(e.target.value);
              }}
              className="form-control rounded-xs"
              id="c1"
              placeholder={`${customerData?.length || customerData?.length} customers`}
            />
          </div>

          <div
            className="list-group list-custom list-group-m rounded-m"
            style={{ minHeight: '14rem' }}>
            {customerData &&
              customerData.map((ledger) => (
                <Link
                  onClick={() =>
                    ledger.type === 'bill' ? editBillForm(ledger) : editPaymentForm(ledger)
                  }
                  key={ledger.uuid}
                  className="list-group-item row"
                  // onClick={() => setModalShow(true)}
                >
                  <div className="pe-3 me-auto col-6">
                    <div className="ps-1">
                      <div>{ledger.name}</div>
                      <span className="p-1 font-800">{formatTime(ledger.date)}</span>
                    </div>
                  </div>
                  <div className="col">
                    <span className="font-800">
                      {ledger.type === 'bill' && ledger.bill_no.slice(0, 10)}
                    </span>
                    <div className="mx-auto" style={{ color: 'red' }}>
                      {ledger.type === 'bill' && numberFormat(ledger.amount)}
                    </div>
                  </div>
                  <div className="col">
                    <div className="mx-auto" style={{ color: 'green' }}>
                      {ledger.type === 'payment' && numberFormat(ledger.amount)}
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
