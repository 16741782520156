/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom';
import { editDisabled, formatTime, numberFormat } from '../share/Utils';
import { useContext, useRef, useEffect } from 'react';
import CommonContext from '../commonContext';

/* eslint-disable react/react-in-jsx-scope */
export default function CombinedData(props) {
  const componentRef = useRef();
  const { data, customerData, netBalance, setActionSheetShow } = props;

  const user = JSON.parse(localStorage.getItem('user'));
  const { contextData, setContextData } = useContext(CommonContext);
  // const isAdmin = user?.roleName === 'Admin';
  useEffect(() => {
    setContextData({
      ...contextData,
      customerMankindFlag: customerData.mankindFlag,
      title: `${customerData?.name?.slice(0, 11)}(${customerData?.place.name})`,
      page: 'profile'
    });
  }, []);

  function editBillForm(event) {
    props.setBillFormValue(event);
    props.setBillModalShow(true);
  }

  function editPaymentForm(event) {
    props.setPaymentFormValue(event);
    props.setPaymentModalShow(true);
  }

  return (
    <>
      <div className={editDisabled() ? 'card card-style disabled-screen' : 'card card-style'}>
        <div className="" ref={componentRef}>
          {/* <h4 className="p-2">
            Total Balance: - <span className="color-red-light">{numberFormat(netBalance)}</span>
          </h4> */}
          {/* <div className="form-custom form-label form-icon mb-3 d-flex">
            <i className="bi bi-search font-14"></i>
            <input type="text" className="form-control rounded-xs" id="c1" placeholder="Search" />
            <i
              onClick={() => setActionSheetShow(true)}
              style={{ position: 'relative', marginLeft: '-3rem' }}
              className="bi bi-funnel-fill"></i>
          </div> */}
          {/* <PDFDownloadLink
            document={<PDFDocument data={data} netBalance={netBalance} />}
            fileName={`${customerData.name.trim()}.pdf`}>
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
          </PDFDownloadLink> */}

          <div
            className="list-group list-custom list-group-m rounded-m"
            style={{ minHeight: '14rem' }}>
            {data &&
              data.map((ledger) => (
                <Link
                  onClick={() =>
                    ledger.type === 'bill' ? editBillForm(ledger) : editPaymentForm(ledger)
                  }
                  key={ledger.uuid}
                  className="list-group-item row"
                  // onClick={() => setModalShow(true)}
                >
                  <div className="pe-3 me-auto col-6">
                    <div className="ps-1">
                      <div>{formatTime(ledger.date)}</div>
                      <span className="p-1 font-800">{numberFormat(ledger.netBalance)}</span>
                    </div>
                  </div>
                  <div className="col">
                    <span className="font-800">
                      {ledger.type === 'bill' && ledger.bill_no.slice(0, 10)}
                    </span>
                    <div className="mx-auto" style={{ color: 'red' }}>
                      {ledger.type === 'bill' && numberFormat(ledger.amount)}
                      {ledger.type === 'bill' && ledger.mankindFlag ? (
                        <span
                          className="font-800"
                          style={{ color: 'blue', alignContent: 'center' }}>
                          M
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="mx-auto" style={{ color: 'green' }}>
                      {ledger.type === 'payment' && numberFormat(ledger.amount)}
                      {ledger.type === 'payment' && ledger.mankindFlag ? (
                        <span
                          className="font-800"
                          style={{ color: 'blue', alignContent: 'center' }}>
                          M
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
}
